<template>
    <section class="almacen-materiales-cotizaciones custom-scroll overflow-auto px-3">
        <div class="row mt-4">
            <div class="col-12 border br-3">
                <p class="col-12 text-center f-16 f-600 my-4">Últimas cincuenta cotizaciones recibidas</p>
                <echart :options="configChartCotizaciones" />
            </div>
        </div>
        <titulo-divisor titulo="Cotizaciones por proveedores" class="my-4">
            <div class="row">
                <div class="col auto">
                    <input-search v-model="query" auto-search mode="server" :search="filtrarCotizaciones"/>
                </div>
                <filter-indicator :count="filterCouunt" @openModalFilter="abrirModalFiltros" @clearFilter="limpiarFiltro"/>
            </div>
        </titulo-divisor>
        <el-collapse v-model="collapse" accordion>
            <el-collapse-item :name="index" v-for="(proveedor, index) in proveedores" :key="index">
                <template slot="title">
                    <div class="d-flex f-600">
                        <label class="cr-pointer mx-4">{{ proveedor.nombre }}</label>
                        <div class="cant-item d-middle-center bg-general br-4 text-white my-auto">
                        {{ proveedor.cantidad_cotizaciones }}
                        </div>
                    </div>
                </template>
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive tabla-cotizaciones">
                            <table class="table border">
                                <thead class="text-5d">
                                    <tr>
                                        <th scope="col" align="center">ID</th>
                                        <th scope="col">Oportunidad</th>
                                        <th scope="col">Materiales</th>
                                        <th scope="col">Valor cotizado</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Días de crédito</th>
                                        <th scope="col">Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(cotizacion, index) in proveedor.cotizaciones" :key="index">
                                        <th scope="row">{{ cotizacion.id }}</th>
                                        <td>{{ cotizacion.oportunidad }}</td>
                                        <td>{{ cotizacion.materiales }}</td>
                                        <td>{{ formatoMoneda(cotizacion.valor_cotizado) }}</td>
                                        <td>{{ cotizacion.fecha | helper-fecha('DD MMM. YYYY') }}</td>
                                        <td>{{ cotizacion.dias_credito }}</td>
                                        <td>
                                            <div class="d-middle-center">
                                                <i class="f-18" :class="estados_cotizacion[cotizacion.id_estado]" />
                                                <p class="font-italic">{{ cotizacion.estado }}</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse>

        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-10 my-3">
                    <p class="input-label-top">Estados</p>
                    <el-select v-model="filter.id_estado" placeholder="Seleccionar estado" size="small" class="w-100" clearable>
                        <el-option v-for="item in filtro_cotizaciones.estados" :key="item.id" :label="item.estado" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Rango de materiales</p>
                    <el-slider v-model="range_materiales" range :min="filtro_cotizaciones.materiales_min" :max="filtro_cotizaciones.materiales_max" />
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Rango de valores</p>
                    <el-slider v-model="range_valor" range :min="filtro_cotizaciones.valor_min" :max="filtro_cotizaciones.valor_max" :format-tooltip="formatTooltipRange" />
                </div>
                <div class="col-5 my-3">
                    <p class="input-label-top">Fecha de inicio</p>
                    <el-date-picker v-model="filter.fecha_min" type="date" placeholder="Seleccionar un día" size="small" class="w-100" :picker-options="pickerOptions"/>
                </div>
                <div class="col-5 my-3">
                    <p class="input-label-top">Fecha final</p>
                    <el-date-picker v-model="filter.fecha_max" type="date" placeholder="Seleccionar un día" size="small" class="w-100" :picker-options="pickerOptions"/>
                </div>
                 <div class="col-11 my-3">
                    <p class="input-label-top">Rango de días de crédito</p>
                    <el-slider v-model="range_credito" range :min="filtro_cotizaciones.dias_credito_min" :max="filtro_cotizaciones.dias_credito_max" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="handleFilter"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
    
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
    data(){
        return{
            buscarListado: '',
            configChartCotizaciones:{
                legend: {
                    data: ['Valor de cotización']
                },

                tooltip: {
                    trigger: 'item',
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    textStyle: {
                        color: '#fff'
                    },
                    formatter: ({ name, seriesName, value, marker }) => {
                        return `${name} <br/> ${marker} ${seriesName} ${this.formatoMoneda(value)}`
                    }
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    show: true,
                },
                yAxis: {
                    type: 'value',
                    data: [],
                },
                series: [
                    {
                        name: 'Valor de cotización',
                        data: [],
                        type: 'line',
                        symbolSize: 10,
                        symbol: 'circle',
                        color: '#0090FF',
                        lineStyle: {
                            width: 1,
                        },
                    }
                ]
            },
            options: [],
            collapse: '',
        }
    },
    computed: {
        ...mapGetters({
            graphic_cotization: 'almacen/materiales/graphic_cotization',
            proveedores:'almacen/cotizaciones/cotizaciones/proveedores',
            filtro_cotizaciones: 'almacen/cotizaciones/cotizaciones/filtro_cotizaciones',
            estados_cotizacion: 'almacen/cotizaciones/cotizaciones/estados_cotizacion'
        }),
        filter: {
            get(){
                return this.$store.getters['almacen/cotizaciones/cotizaciones/filter']
            },
            set(val){
                this.$store.commit('almacen/cotizaciones/cotizaciones/set_filter', val)
            }
        },
        query: {
            get(){
                return this.$store.getters['almacen/cotizaciones/cotizaciones/query']
            },
            set(val){
                this.$store.commit('almacen/cotizaciones/cotizaciones/set_query', val)
            }
        },
        range_materiales: {
            get(){
                return [
                    this.filter.materiales_min,
                    this.filter.materiales_max
                ]
            },
            set(val){
                this.filter.materiales_min = val[0]
                this.filter.materiales_max = val[1]
            }
        },
        range_valor: {
            get(){
                return [
                    this.filter.valor_min,
                    this.filter.valor_max
                ]
            },
            set(val){
                this.filter.valor_min = val[0]
                this.filter.valor_max = val[1]
            }
        },
        range_credito: {
            get(){
                return [
                    this.filter.dias_credito_min,
                    this.filter.dias_credito_max
                ]
            },
            set(val){
                this.filter.dias_credito_min = val[0]
                this.filter.dias_credito_max = val[1]
            }
        },
        pickerOptions(){
            return {
                disabledDate:(time) => {
                    const fecha_inicio = this.filtro_cotizaciones.fecha_cotizacion_min
                    const fecha_fin = this.filtro_cotizaciones.fecha_cotizacion_max
                    const date = moment(time).format('YYYY-MM-DD')
                    return fecha_inicio && fecha_fin
                        && !moment(date).isBetween(
                            moment(fecha_inicio).format('YYYY-MM-DD'),
                            moment(fecha_fin).format('YYYY-MM-DD'),
                            undefined, '[]'
                        )
                }
            }
        },
        filterCouunt(){
            this.filter.count()
        },
        idMaterial(){
            return this.$route.params.id
        },
    },
    async created() {
        await this.listarMaterialCotizacion()
        this.llenadoGrafica(); /* recordar descomentar */
        await this.Action_get_filtro_cotizaciones(this.idMaterial)
        this.Action_clear_filter()
        this.filtrarCotizaciones()
    },
    methods:{
        ...mapActions({
            Action_get_material_cotizations: 'almacen/materiales/Action_get_material_cotizations',
            Action_get_cotizaciones: 'almacen/cotizaciones/cotizaciones/Action_get_cotizaciones',
            Action_get_filtro_cotizaciones: 'almacen/cotizaciones/cotizaciones/Action_get_filtro_cotizaciones',
            Action_clear_filter: 'almacen/cotizaciones/cotizaciones/Action_clear_filter'
        }),
        llenadoGrafica() {
            let fecha = [];
            let valor = [];
            this.graphic_cotization.forEach(e => {
                fecha.push(moment(e.fecha, 'YYYY-MM-DD').format('YYYY-MM-DD'));
                valor.push(e.valor)
            });
            this.configChartCotizaciones.xAxis.data = fecha;
            this.configChartCotizaciones.series[0].data = valor;
        },
        async listarMaterialCotizacion(){
            await this.Action_get_material_cotizations({id_almacen_material: this.$route.params.id})
            this.llenadoGrafica()
        },
        verOrdenCompra(id){
            this.$router.push({ name: 'compras.ver.orden', params:{ id_orden : id } })
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        handleFilter(){
            this.filtrarCotizaciones()
            this.$refs.abrirModalFiltros.toggle()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        async filtrarCotizaciones(){
            await this.Action_get_cotizaciones(this.idMaterial)
        },
        async limpiarFiltro(){
            this.Action_clear_filter()
            await this.Action_get_cotizaciones(this.idMaterial)
        }
    }
}
</script>

<style lang="scss" scoped>

th, td{
    text-align: center;
}
.almacen-materiales-cotizaciones{
    height: calc(100vh - 227px);
    .cant-item{
        width: 44px;
        height: 24px;
    }
   .recibido{
        color:#3CD273;
   }
   .enviado{
        color:#0090FF;
   }
   .tabla-cotizaciones{
        .table thead th{
            border-bottom: 1px solid var(--color-general);
        }
   }
}
</style>